.skeleton{
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.skeleton-wrapper {
  padding: 50px 100px;
  margin-top: 80px;
  max-width: 1600px;
  margin: 80px auto;
}
.herocard {
  display: flex;
  justify-content: space-between;
}
.skeleton-wrapper .herocard__body {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @extend .skeleton
}
.skeleton-wrapper .herocard__head  {
  width: 48%;
  @extend .skeleton 
}
.skeleton .thumbnail {
  margin-left: auto;
  display: block;
  min-height: 300px;
  background: #d2dbe2;
  border-radius: 7px;
}
.skeleton .text-1 {
  width: 30%;
  margin-top: 10px;
  background: #d2dbe2;
  border-radius: 7px;
  min-height: 10px;
}
.skeleton .text-2 {
  width: 85%;
  margin-top: 10px;
  background: #d2dbe2;
  border-radius: 7px;
  min-height: 30px;
}
.skeleton .text-3 {
  width: 90%;
  margin-top: 5px;
  background: #d2dbe2;
  border-radius: 7px;
  min-height: 10px;
}
.skeleton .text-4 {
  width: 40%;
  margin-top: 10px;
  background: #d2dbe2;
  border-radius: 7px;
  min-height: 10px;
}

@media (max-width: 800px) {
  .herocard {
    display: flex;
    flex-direction: column-reverse;
  }
  .skeleton-wrapper {
    .herocard__body {
      width: 100%;
    }
    .herocard__head {
      width: 100%;
    }
  }
  .skeleton-wrapper {
    padding: 50px 20px !important;
  }
}
@media (max-width: 1250px) {
  .skeleton-wrapper {
    padding: 50px;
  }
}
