.codinova-logo {
  width: 75px;

  #codinovaLogoLayer1 {
    animation: item1Movement 1s;
  }

  #codinovaLogoLayer2 {
    animation: item2Movement 0.9s;
  }

  #codinovaLogoLayer3 {
    animation: item3Movement 0.7s;
  }

  #codinovaLogoLayer4 {
    animation: item4Movement 0.4s;
  }

  #codinovaLogoLayer5 {
    animation: item5Movement 0.5s;
  }

  #codinovaLogoLayer6 {
    animation: item6Movement 0.8s;
  }

  #codinovaLogoLayer6 {
    animation: item6Movement 0.9s;
  }

  #codinovaLogoLayer7 {
    animation: item7Movement 1.3s;
  }

  #codinovaLogoLayer8 {
    animation: item8Movement 1s;
  }

  @keyframes item1Movement {
    0% {
      transform: translate3d(0, 7px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes item2Movement {
    0% {
      transform: translate3d(0, 27px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes item3Movement {
    0% {
      transform: translate3d(0, 80px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes item4Movement {
    0% {
      transform: translate3d(0, 80px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes item5Movement {
    0% {
      transform: translate3d(0, 30px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes item6Movement {
    0% {
      transform: translate3d(0, 80px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes item7Movement {
    0% {
      transform: translate3d(0, 150px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes item8Movement {
    0% {
      transform: translate3d(0, 180px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

.codinova-text-logo {
  #codinovaLogoLayer1 {
    animation: textItem1Movement 0.5s forwards;
  }

  #codinovaLogoLayer2 {
    animation: textItem2Movement 0.55s;
  }

  #codinovaLogoLayer3 {
    animation: textItem3Movement 0.6s;
  }

  #codinovaLogoLayer4 {
    animation: textItem4Movement 0.8s;
  }

  #codinovaLogoLayer5 {
    animation: textItem5Movement 0.9s;
  }

  #codinovaLogoLayer6 {
    animation: textItem6Movement 1s;
  }

  #codinovaLogoLayer7 {
    animation: textItem7Animation 1.2s;
  }
  @keyframes textItem1Movement {
    0% {
      transform: translate3d(10px, 80px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes textItem2Movement {
    0% {
      transform: translate3d(20px, 70px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes textItem3Movement {
    0% {
      transform: translate3d(30px, 108px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes textItem4Movement {
    0% {
      transform: translate3d(21px, 70px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes textItem5Movement {
    0% {
      transform: translate3d(0, 150px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes textItem6Movement {
    0% {
      transform: translate3d(0, 140px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes textItem7Animation {
    0% {
      font-weight: 100;
    }
    100% {
      font-weight: 600;
    }
  }
}
