.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
  transform: scale(1);
  transition: .2s ease-in-out;
  .card__head{
    height:288px;
    img {
      width: 100%;
      height: 18rem;
      object-fit: cover;
    }
  }
  
  .no-image-blog {
    height: 18rem;
    background-color: rgb(184, 184, 184);
  }
}
.tag {
  max-width: 35px;
  color: #45b7cf;
}
.blog__tag,
.user {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #6e7976;
  font-size: 14px;
  font-weight: 520;
}
.card__body {
  padding: 1rem;
  .blog_heading{
    width: 100%;
    a {
      text-decoration: none;
      color: #000;
      h4 {
        font-size: 1.5rem;
        text-transform: capitalize;
        height: 58px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
 
  .description {
    overflow: hidden;
  }

}
.user div {
  margin-bottom: 10px;
}
.card__body .blog__tag,
h4,
p,
.user {
  margin: 20px 0;
}

.card:hover{
  transform: scale(1.01);
  transition: .2s ease-in-out;
}
