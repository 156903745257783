.site-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // background: #003e7b;
  text-align: center;
  background: #043d4d;
  padding: 40px;
  color: #fff;
}
