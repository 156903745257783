.heroblog-card {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 50px 100px 0; 
  margin-top: 80px;
  font-family: "Quicksand", sans-serif;
  flex-flow: row-reverse;
  max-width: 1600px;
  margin: 80px auto 20px;
  
  .herocard__body {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      text-decoration: none;
      color: #000;
    }
    h4 {
      font-size: 2.5rem;
    }
  }
  .herocard__head {
    width: 48%;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      height: 400px;
    }
    .no-image-blog {
      height: 400px;
      background-color: rgb(184, 184, 184);
      border-radius: 5px;
    }
  }
}

@media (max-width: 800px) {
  .heroblog-card {
    flex-direction: column;
    padding: 50px 20px !important;
    .herocard__body {
      width: 100%;
      margin-top: 20px;
    }
    .herocard__head {
      width: 100%;
      img {
        height: 300px;
      }
    }
  }
}
@media (max-width: 1250px) {
  .heroblog-card {
    padding: 50px;
  }
}
