.blogSkeleton-wrapper {
   position: relative;
}
.blogCard {
  .skeleton{
    position: relative;
    overflow: hidden;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .text-1,
  .text-2,
  .text-3,
  .text-4,
  .text-5,
  .text-6 {
    background: #d2dbe2;
    border-radius: 7px;
  }
  
  .skeleton .thumbnail {
    margin-left: auto;
    display: block;
    min-height: 300px;
  }
  .skeleton .text-1 {
    width: 60%;
    min-height: 10px;
    margin-top: 8px;
  }
  .skeleton .text-2 {
    width: 100%;
    margin-top: 10px;
    min-height: 18px;
  }
  .skeleton .text-3 {
    width: 40%;
    margin-top: 5px;
    min-height: 18px;
  }
  .skeleton .text-4 {
    width: 90%;
    margin-top: 5px;
    min-height: 10px;
  }
  .skeleton .text-5 {
    width: 40%;
    margin-top: 10px;
    min-height: 10px;
  }
  .skeleton .text-6 {
    width: 30%;
    margin-top: 10px;
    min-height: 10px;
  }
}
@media (max-width: 1250px) {
  .blogSkeleton-wrapper {
    padding: 50px 0 !important;
  }
}
@media (max-width: 800px) {
  .blogCard {
    width: 100%;
  }
  .blogSkeleton-wrapper {
    padding: 50px 0 !important;
  }
}
