.blogDetails {
  width: 50%;
  margin: auto;

  .text-1,
  .text-2,
  .text-3 {
    background: #d2dbe2;
    border-radius: 7px;
  }

  .skeleton {
    position: relative;
    overflow: hidden;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
    .text-1 {
      width: 100%;
      min-height: 20px;
    }
    .text-2 {
      margin: auto;
      width: 50%;
      margin-top: 10px;
      min-height: 20px;
    }
    .text-3 {
      width: 100%;
      margin-top: 5px;
      min-height: 10px;
    }
    .text-4 {
      width: 100%;
      margin-top: 10px;
      min-height: 500px;
    }
  }
}
@media (max-width: 800px) {
  .blogDetails {
    width: 80% !important;
  }
}
@media (max-width: 1250px) {
  .blogDetails {
    width: 70%;
  }
}
