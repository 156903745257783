.btn-container {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

button {
  cursor: pointer;
  background-color: #fff;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  border: 2px solid #043d4d;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: #043d4d;
  transition: all ease 0.5s;
}

button:hover {
  color: #fff;
  background-color: #043d4d;
}
