.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 50px 100px;
  font-family: "Quicksand", sans-serif;
  max-width: 1600px;
  margin: auto;
  box-sizing: border-box;
}
.latest-article {
  font-size: 32px;
  font-weight: 600;
  padding: 0 100px;
  max-width: 1600px;
  margin: auto;
  margin-bottom: 50px;
}

.blogList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1600px;
  margin: auto;
  padding: 0px 100px 50px 100px;
  box-sizing: border-box;
  width: 100%;
}

@media (max-width: 1250px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    padding: 50px;
  }
  .latest-article {
    padding: 0 50px;
  }
  .blogList {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 50px 50px !important;
  }
}

@media (max-width: 800px) {
  .latest-article {
    padding: 0 20px !important;
  }
  .blogList {
    grid-template-columns: repeat(1, 1fr) !important;
    padding: 0 20px  50px !important;
  }
}

@media (max-width: 500px) {
  .latest-article {
    padding: 0 20px !important;
  }
  
}