.blogDetailsContainer {
  position: relative;
  width: 50%;
  margin: auto;
  padding: 100px 0;
  .icon-blogHeading{
    display: flex;
    h1{
      margin: 0px ;
    }
  }
  .blog_title {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 700;
    font-family: "Poppins";
  }
  .blog_author {
    color: #403f3e;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    p {
      margin-inline: 8px;
    }
  }
  .separator {
    overflow: hidden;
    a {
      margin: 0 !important;
      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
}

@media (max-width: 550px) {
  .blogDetailsContainer {
    .blog_title {
      font-size: 30px;
      line-height: 30px;
    }
    .blog_author {
      flex-direction: column;
      margin: 15px;
      font-size: 12px;
      p {
        margin: 2px;
      }
      span {
        display: none;
      }
    }
    .blog_content img {
      width: 100%;
    }
  }
}

@media (max-width:340px) {
  .icon-blogHeading{
    flex-direction: column;
  }
  .icon-blogHeading .backArrow svg{
    padding-top: 0px;
  }
  .icon-blogHeading .backArrow svg:hover{
    transform: translateX(0px);
  }
}

@media (max-width: 800px) {
  .blogDetailsContainer {
    width: 90% !important;
    overflow: hidden;
    padding: 100px 0px 0px 0px;
  }
}
@media (max-width: 1250px) {
  .blogDetailsContainer {
    width: 70%;
  }
}
