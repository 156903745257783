.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: all 1s ease-in-out;
}
.sticky-header {
  transition: all 1s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 14px 44px;
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.3);
}
.header-content {
  max-width: 1600px;
  height: 80px;
  margin: auto;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.navbar {
  display: flex;
  align-items: center;
  column-gap: 25px;
  a {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-decoration: none;
    color: rgb(34, 94, 150);
    font-size: 16px;
    font-weight: 500;
    position: relative;
    transition: 0.2s ease;
    &:after {
      content: "";
      display: block;
      height: 2.5px;
      left: 50%;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
      background: rgb(34, 94, 150);
    }
    &:hover:after {
      width: 100%;
      left: 0;
    }
  }

  .contact-btn {
    border-radius: 50px;
    color: #fff !important;
    font-size: 14px;
    border: none;
    padding: 12px 24px;
    background-color: rgb(34, 94, 150);
    &:hover {
      background-color: rgb(27, 74, 118);
    }
    &:hover:after {
      width: 0;
    }
  }
}

.insightsBtn.active::after {
  left: 0;
  width: 100%;
}

.hamburger {
  display: none;
  #close-icon {
    display: none;
  }
}
.logo-link {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .header-content {
    padding: 0px 10px;
  }
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    left: -100%;
    top: 80px;
    transition: all ease 0.1s;
    height: 100vh;
    a {
      padding: 20px 0 !important;
      border-bottom: 2px solid #fff !important;
      width: 100%;
      color: #fff !important;
      font-size: large !important;
      &:hover {
        background: none !important;
      }
      &:hover:after {
        width: 0;
      }
    }
    .contact-btn {
      background: none;
      border-radius: 0;
    }
  }
  .navbar.is-active {
    background-color: rgba(51, 51, 51, 0.829);
    position: absolute;
    left: 0;
    right: 0;
    padding: 15px 30px;
    transition: all ease 0.2s;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    img {
      width: 35px;
    }
    #close-icon {
      width: 25px;
    }
  }
  .is-active.hamburger #close-icon {
    display: block;
  }
  .is-active.hamburger #hamburger-icon {
    display: none;
  }

  .insightsBtn.active {
    color: #19A9D1 !important;
    &::after {
      left: 50%;
      width: 0;
    }
  }
}

.codinova-logo {
  width: 55px !important;
  transition: transform 0.2s;
  @media (min-width: 640px) {
    display: none;
  }
  @media (min-width: 480px) {
    transform: scale(1.1);
  }
}
.codinova-text-logo {
  width: 200px;
  display: none;
  @media (min-width: 640px) {
    display: block;
  }
}
