.backArrow {
  svg {
    display: inline-block;
    width: 30px;
    padding-top: 15px;
    transition: all ease 0.2s;
  }
  height: 30px;
  width: 50px;
}

.backArrow:hover svg {
  transition: all ease 0.2s;
   transform: translate(-5px);
}
